.main_agenda_reader_component{@apply fixed bottom-[55px] left-[calc(100%_-_482px)] z-[99] w-[450px] shadow-[0_0_0_6px] shadow-interactive-30 rounded-[6px] overflow-hidden max-h-[560px] transition-[left_.4s] flex flex-col;

  &[data-align-left='true']{@apply left-[32px] fixed;}

  .main_header_reader{@apply p-[10px_18px] flex justify-between bg-interactive-10 items-center cursor-pointer;}


  .main_editor{font-family: 'Helvetica', 'Arial', sans-serif; @apply p-[0px_16px_8px] block;
    .ce-block__content{max-width: 100%;}
    .ce-toolbar__content{max-width: 100%;}
    .cdx-block{@apply py-1;}
    .codex-editor__redactor{margin-right: 0;}
    .ce-toolbar__content{max-width: calc(100% - 98px);}
    textarea.ce-code__textarea{resize: none; min-height: unset !important;}
    h1,h2,h3,h4,h5,h6{@apply font-bold;}
    h1{@apply text-20;}
    h2{@apply text-16;}
    h3{@apply text-15;}
    h4{@apply text-14;}
    h5{@apply text-14;}
    h6{@apply text-13;}
    p{@apply text-14;}
    .tc-wrap{

      --color-border : #0D0E29;

      .tc-table{@apply border-l border-dark-100; }

      .tc-add-column{@apply border-r border-dark-100; }

      .tc-table--heading .tc-row:first-child{@apply border-b; background: #d5dce4;
        &::after{@apply -bottom-[1px] border-b; }
      }
    }
    .cdx-marker{
      &.marker-interactive{@apply bg-interactive-20;}
      &.marker-green{@apply bg-green-20;}
    }
    .ce-delimiter{
      &::before{content: ""; height: 80px;}
      &::after{@apply content-[""] w-full h-[1px] bg-dark-20 absolute left-0 top-1/2 ;}
    }
    .image-tool__caption{display: none;}

    .ce-justify-text--justify{text-align: justify;}
  }
}

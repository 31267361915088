// Icon • 10
.icon_10{

	// Size
	$size: 10;


	// Default
	&.default{@include icon(10);

		// Count
		$count: 0;

		// Icons
		&.chevron{@include bg_pos_icon($size, $count); $count: $count + 1;
			&.top{transform: rotate(180deg);}
			&.right{transform: rotate(-90deg);}
			&.left{transform: rotate(90deg);}
		}
		&.draggable{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.close{@include bg_pos_icon($size, $count); $count: $count + 1;}
	}
}



// Icon • 16
.icon_16{

	// Size
	$size: 16;


	// Default
	&.default{@include icon(16);

		// Count
		$count: 0;

		// Icons
		&.chevron{@include bg_pos_icon($size, $count); $count: $count + 1;
			&.top{transform: rotate(180deg);}
			&.right{transform: rotate(-90deg);}
			&.left{transform: rotate(90deg);}
		}
		&.delete{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.cross{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.edit, &.pen{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.calendar{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.settings{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.duplicate{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.expand{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.collapsed{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.edit_box{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.arrow{@include bg_pos_icon($size, $count); $count: $count + 1;
			&.top{transform: rotate(180deg);}
			&.right{transform: rotate(-90deg);}
			&.left{transform: rotate(90deg);}
		}
		&.search{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.clock{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.to_folder{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.doc{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.note{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.paper_clip{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.download{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.upload{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.user{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.options{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.in_entity{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.out_entity{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.quotes{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.text_box{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.folder{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.layers{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.layer{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.layer_fill{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.piano{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.id_scan{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.text_scan{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.book{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.pie_chart{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.entity{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.plus{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.support{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.wrench{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.printer{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.info{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.question{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.check{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.ban{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.lock{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.stopwatch{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.lightning{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.danger{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.danger_corp{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.update{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.megaphone{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.eur{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.loader{@include bg_pos_icon($size, $count); $count: $count + 1;  animation: infiniteLoad infinite .4s}
		&.logout{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.back_platform{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.percentage{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.download_diff{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.collapse_icon{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.book_disabled{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.arrow_extreme{@include bg_pos_icon($size, $count); $count: $count + 1;
			&.top{transform: rotate(180deg);}
			&.right{transform: rotate(-90deg);}
			&.left{transform: rotate(90deg);}
		}
		&.course{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.approved{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.filters{@include bg_pos_icon($size, $count); $count: $count + 1;}
	}


	// Status
	&.status{@include icon(16, status);

		// Count
		$count: 0;

		// Icons
		&.uncompleted{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.completed{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.unrelated{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.stalled{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.planned{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.processing{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.message{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.eight_point_star{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.danger{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.like{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.four_point_star{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.eye{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.notcompleted{@include bg_pos_icon($size, $count); $count: $count + 1;}
	}




	@keyframes infiniteLoad {
		0%{transform: rotate(0deg)}
		100%{transform: rotate(360deg)}
	}
}





// Icon • 20
.icon_20{

	// Size
	$size: 20;


	// Default
	&.status{@include icon(20, status);

		// Count
		$count: 0;

		// Icons
		&.uncompleted{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.completed{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.unrelated{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.stalled{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.planned{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.processing{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.message{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.eight_point_star{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.danger{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.like{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.four_point_star{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.eye{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.notcompleted{@include bg_pos_icon($size, $count); $count: $count + 1;}
	}
}




// Icon • 24
.icon_24{

	// Size
	$size: 24;


	// Default
	&.default{@include icon(24);

		// Count
		$count: 0;

		// Icons
		&.piano{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.calendar{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.edit{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.delete{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.settings{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.duplicate{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.import_layers{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.edit_box{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.printer{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.support{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.note{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.download{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.link_out{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.doc{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.wrench{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.in_entity{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.approved{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.preview{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.download_diff{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.branch{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.attachments{@include bg_pos_icon($size, $count); $count: $count + 1;}
	}
}


// Icon • 30
.icon_30{

	// Size
	$size: 30;


	// Default
	&.default{@include icon(30);

		// Count
		$count: 0;

		// Icons
		&.note{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.piano{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.branch{@include bg_pos_icon($size, $count); $count: $count + 1;}
	}
}


// Icon • 75
.icon_75{

	// Size
	$size: 75;


	// Default
	&.default{@include icon(75);

		// Count
		$count: 0;

		// Icons
		&.archive{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.archive_plan{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.archive_note{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.archive_entity{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.no_activities{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.approvals{@include bg_pos_icon($size, $count); $count: $count + 1;}
		&.list_boxs{@include bg_pos_icon($size, $count); $count: $count + 1;}
	}
}



.main_user{@apply flex items-center gap-3 p-1 pr-3 shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-full cursor-pointer select-none;
    .initals{@apply w-7 h-7 bg-primary-10 flex justify-center items-center text-10 font-bold rounded-full text-primary-100;}
    .role{@apply text-12 font-medium;}
}


.main_content{@apply min-w-[120px] shadow-[0_0_0_1px_theme("colors.dark.10"),0_8px_20px_theme("colors.dark.100")1a] rounded-[10px] bg-white overflow-hidden;
    .main_list{
        .main_item{@apply py-3 px-4 gap-[6px] flex items-center text-13 hover:opacity-70 cursor-pointer whitespace-nowrap;
            .main_icon{@apply flex opacity-70;}
        }
    }
}
@layer components{

    .main_modal_container{@apply fixed bg-white/80 w-full h-full top-0 left-0 z-[999] flex items-center justify-center;

        .wrap_modal_container{@apply p-[50px] max-h-full overflow-auto w-full;
            .container_modal_prewrap{@apply pointer-events-none;}

            .main_modal_content{@apply mx-auto max-w-[385px] bg-white rounded-[10px] shadow-[0_0_0_1px_theme("colors.dark.100")1a,0_0_1px_theme("colors.dark.100")1a,0_5px_10px_theme("colors.dark.100")1a] relative; pointer-events: all !important;

                &.medium_size_modal{
                    max-width: 480px;
                }

                // Close
                .main_close{@apply z-10 absolute -top-3 -right-3 hover:bg-dark-100 flex cursor-pointer rounded-full p-[5px] shadow-[0_1px_3px_theme("colors.dark.100")1a] bg-dark-70;}

                // Header modal
                .main_header_modal{@apply pt-9 px-[40px] text-left;
                    .title{@apply text-18 font-bold;}

                    .main_back_button{@apply p-2 flex-none rounded-[2px] shadow-[inset_0_0_0_1px_theme("colors.dark.10")] flex w-fit cursor-pointer;}

                    &.theme_gray{@apply pb-7 text-center bg-dark-10;}

                    &.theme_dark{@apply pb-7 text-white text-center bg-gradient-to-tr from-dark-100 from-0% to-dark-80 to-100%;}

                    &.theme_primary{@apply pb-7 text-white text-center bg-gradient-to-tr from-primary-100 from-0% to-primary-80 to-100%;}


                    &.flex_header{@apply flex justify-between items-center;
                        .left_content{@apply flex gap-5 items-center;

                            .main_desc{@apply grid gap-1;
                                .desc{@apply text-14 text-dark-70;}
                            }
                        }
                    }

                    &.divide_header{@apply shadow-[0_1px_0_theme("colors.dark.10")] pb-5;}
                }

                // Content modal
                .main_body_modal{@apply pt-5 pb-9 px-11;

                    .main_message_box{@apply px-4 py-[10px] rounded-[4px] flex gap-3;
                        &.box_info{@apply shadow-[inset_1px_-1px_0_theme("colors.interactive.100")] bg-interactive-10;}
                        .main_icon{@apply flex mt-1 flex-none;}
                        .message{@apply text-12;}
                    }

                    .main_sub_intro{
                        .wrap_sub_intro{@apply flex gap-2 items-center;
                            .main_icon{@apply flex;}
                            .subintro{@apply uppercase text-12 font-bold text-dark-50;}
                        }

                        .main_description{@apply pt-[6px] text-14;}

                        .main_counter_container{@apply flex items-center pt-[6px] gap-3;
                            .main_counter{@apply flex gap-2 items-center font-medium text-13;
                                .main_icon{@apply flex;}
                            }
                        }
                    }

                }

                // Footer modal
                .main_footer_modal{@apply pb-9 px-11 flex justify-between items-center gap-5;
                    .right_content{@apply justify-self-end flex justify-end  items-center gap-5 w-full;}
                    &.theme_gray{@apply pt-8 bg-dark-10;}

                    &.divide_footer{@apply shadow-[0_-1px_0_theme("colors.dark.10")] pt-5;}
                }

            }
        }









        &.modal_in{ animation: modalIn 200ms forwards ease-in; }

        &.modal_out{animation: modalIn 200ms forwards ease-in reverse;}


        .modal_content_in{ animation: modalContentIn 200ms forwards ease-in; }

        .modal_content_out{ animation: modalContentIn 200ms forwards ease-in reverse;}

        @keyframes modalIn {
            0%{opacity: 0;}
            100%{opacity: 1;}
        }

        @keyframes modalContentIn {
            0%{ opacity: 0; transform: scale(.4);}
            100%{ opacity: 1; transform: scale(1);}
        }
    }

}

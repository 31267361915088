.main_comp_card_course_content{@apply rounded-[2px] shadow-[0_1px_1px_theme("colors.dark.100")1a] bg-white p-5 mb-2 relative overflow-hidden;

    &.link_variant{
        @apply /*hover:bg-interactive-100/10*/ transition-colors;
    }

    &.is_dragging{@apply shadow-[inset_0_0px_0_1px_theme("colors.dark.100")1a,0_2px_4px_theme("colors.dark.100")1a] relative;}
    &:not(.not_draggable):hover{
        .main_intro{
            .wrap_intro{
                .main_icon{
                    &.type{@apply hidden;}
                    &.draggable{@apply flex;}
                }
            }

            .main_actions{@apply flex;}
        }

    }
    &.not_draggable{@apply mb-4;}
    
    .main_intro{@apply flex items-center gap-2 justify-between;
        .wrap_intro{@apply flex items-center gap-2 max-w-full overflow-hidden;
            .main_icon{@apply flex flex-none;
                &.draggable{@apply p-[3px] shadow-[0_0_1px_theme("colors.dark.100")1a,0_1px_2px_theme("colors.dark.100")1a] rounded-[2px] hidden;}
            }
            .title{@apply text-dark-50 uppercase text-12 font-bold truncate;}
        }

        .main_actions{@apply items-center gap-2 hidden flex-none;
            .main_action{@apply flex p-[1px] rounded-[2px] hover:bg-interactive-10;}
        }
    }

    .main_desc{@apply pt-3 pl-6 flex gap-[16px] items-start justify-between;
        .desc{@apply text-13 line-clamp-2;}

        .main_image{@apply flex shadow-[0_0_0_1px] rounded-[4px] shadow-dark-100/10 w-[50px] h-[40px] relative bg-dark-5 items-center justify-center flex-none;
            .image{@apply absolute w-full h-full rounded-[4px] object-cover;}
        }
    }


    &.active{
        &::before{@apply content-[""] absolute left-0 top-0 h-full w-[6px] bg-primary-100;}
    }
}
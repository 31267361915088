.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.copy_plan_popup{ max-width: 900px;
                .main_header_modal{@apply px-7 pt-7;
                
                    .main_action_detail{@apply pt-5;
                        .main_intro{@apply flex items-center gap-2;
                            .main_icon{@apply flex;}
                            .intro{@apply text-12 font-bold text-dark-50 uppercase;}
                        }

                        .name{@apply text-14 pt-2;}
                    }
                }


                .main_body_modal{@apply px-7 pt-5 pb-7 flex h-[500px] gap-7;

                    .main_list_entities{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[8px] flex-1 flex flex-col;
                    

                        .main_heading_list{@apply p-5 pb-6;
                            .main_intro{@apply flex justify-between items-center;
                                .title{@apply text-16 font-bold;}
                            }

                            .main_filters{@apply flex justify-between gap-5;}
                        }

                        .main_table_entity{@apply max-h-full pb-5 px-5;}
                    }

                    .main_list_selected{@apply w-1/3 shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[8px] bg-light-gray flex flex-col;
                        .main_counter{@apply shadow-[0_1px_0_theme("colors.dark.10")] p-5;
                            .title{@apply text-16 text-dark-70 font-medium;
                                b{@apply text-dark-100 font-bold;}
                            }
                        }

                        .list_container{@apply max-h-full flex overflow-hidden;
                            .main_list_items{@apply p-5 w-full;
                                .wrap_list_items{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[4px] bg-white overflow-hidden;
                                    .item_entity{@apply py-[5px] px-3 bg-white shadow-[inset_0_-1px_0_theme("colors.dark.10")] last:shadow-none flex items-center justify-between;
                                        .main_name{@apply flex items-center gap-3 ;
                                            .logo{@apply w-7 h-7 flex-none object-contain;}
                                            .main_icon{@apply flex flex-none p-2;}
                                            .name{@apply line-clamp-1;}
                                        }
                                        .main_delete{@apply hidden cursor-pointer;}
    
                                        &:hover{
                                            .main_delete{@apply flex;}
                                        }
                                    }
                                }
                            }
                        }
                    }


                    .main_empty_container{@apply flex justify-center items-center h-full p-6;}
                
                }

                .main_footer_modal{@apply px-7 pb-7;}
            }
        }
    }
}
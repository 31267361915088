.main_status_dropdown{@apply z-[10] relative;

    &.open{@apply z-[11] relative;}

    .main_toggle{@apply flex items-center min-w-[180px] justify-between gap-7 py-2 px-[10px] rounded-full cursor-pointer shadow-[inset_0_0_0_1px_theme("colors.dark.10")] bg-white;

        &.bg_toggle{@apply shadow-none;}

        .main_icon{@apply flex rounded-full;}

        .main_name{@apply flex items-center gap-2 uppercase text-12 text-dark-70 font-semibold;}

        /*&.uncompleted{@apply shadow-[inset_0_0_0_1px_theme("colors.dark.10")] bg-white;
            .main_name{@apply text-12 text-dark-70 font-normal;}
        }

        &.completed{@apply bg-green-20;}

        &.notcompleted{@apply bg-red-20;}

        &.planned{@apply bg-blue-10;}

        &.processing{@apply bg-yellow-20;}

        &.stalled{@apply bg-dark-10;}

        &.unrelated{@apply bg-fuxia-10;}*/
    }



    .main_content{@apply overflow-hidden shadow-[0_0_0_1px_theme("colors.dark.10"),0_8px_20px_theme("colors.dark.100")1a] rounded-[10px] min-w-[530px] bg-white;

        .main_list_status{@apply grid py-5;
            .item_status{@apply px-5 py-3 last:pb-0 first:pt-0 shadow-[0_1px_0_theme("colors.dark.10")] last:shadow-none flex items-center cursor-pointer;
                .main_desc{@apply flex-1 pr-4;
                    .title{@apply font-medium text-14;}
                    .desc{@apply text-dark-70 text-12;}
                }
            }
        }
    }


    &.small{


        .main_content{min-width: 360px;}
    }


}

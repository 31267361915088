.main_goals_container{@apply flex ;

    .main_side_list{@apply w-[224px] flex-none;

        .main_list_actions{@apply fixed overflow-auto pt-[128px] top-0 flex h-full;
            .wrap_list_actions{@apply w-[216px] grid gap-3 overflow-auto pt-7 pb-9 max-h-full pr-5;
                .item_action{@apply bg-white rounded-[2px] mb-3 flex items-center gap-3 px-3 py-[6px] cursor-pointer;
                    &.active{@apply shadow-[inset_5px_0_0_theme("colors.primary.100")] cursor-pointer;}
                    &.error{@apply bg-red-100/[.20] text-red-100;}
                    
                    .left_content{@apply flex items-center gap-2 overflow-hidden w-full;
                        .main_icon{@apply flex py-1;
                            &.type{@apply flex;}
                            &.draggable{@apply p-[5px_3px] shadow-[0_0_1px_theme("colors.dark.100")1a,0_1px_2px_theme("colors.dark.100")1a] rounded-[2px] hidden bg-white h-fit;}
                        }
                        .name{@apply text-14 leading-[1.3em] font-medium truncate max-w-full flex-1;}
                    }


                    &:not(.not_draggable):hover{
                        .left_content{
                            .main_icon{
                                &.type{@apply hidden;}
                                &.draggable{@apply flex;}
                            }
                        }
                    }
                }
            }
        }
    }


    .main_list_form{@apply w-full flex flex-col items-center pb-[150px] gap-5 max-w-[900px] mx-auto;
        .item_form{@apply w-full flex flex-col items-center gap-5;
        
            .main_form{@apply w-full;}
        }
    }
}
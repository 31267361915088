.main_dropdown_filter{@apply relative;

    .main_toggle_filter{@apply shadow-[inset_0_0_0_1px_theme("colors.dark.30")] text-dark-70 inline-flex pt-[6px] pb-[5px] px-5 rounded-full cursor-pointer select-none gap-3
            hover:shadow-[inset_0_0_0_1px_theme("colors.dark.100")] hover:text-dark-100 font-semibold text-14 items-center
        ;
        &.open{@apply  shadow-[inset_0_0_0_1px_theme("colors.interactive.100"),0_4px_8px_theme("colors.dark.10")] bg-interactive-10;}


        .main_counter{@apply w-5 h-5 bg-interactive-100 rounded-full flex items-center justify-center text-center;
            .counter{@apply text-11 leading-[10px] font-medium text-white;}
            .icon{@apply hidden;}

            /* &:hover{
                .counter{@apply hidden;}
                .icon{@apply block;}
            } */

            .tooltip{@apply bg-dark-100 text-white rounded-[8px] shadow-[0_4px_12px_theme("colors.dark.10")] px-5 py-3 text-13 font-semibold;}
            .tooltip_arrow{@apply rounded-br-[2px] bg-dark-100;}
            
        }
    }


    .main_popover_filter{@apply absolute overflow-hidden shadow-[0_0_0_1px_theme("colors.dark.10"),0_8px_20px_theme("colors.dark.10")] rounded-[10px] bg-white mt-4 min-w-[300px] flex flex-col z-10;
        
        .popover_header{@apply shadow-[0_1px_0_theme("colors.dark.10")] p-5;}
        .popover_content{@apply flex-1 min-h-[150px] max-h-[220px] overflow-auto;
            
            .main_list_items{@apply grid gap-3 px-5 py-5;}

            .main_list_collpase{@apply px-5 py-5 grid gap-3;
                .main_list_items{@apply grid gap-3 px-5 py-3;}
                .main_toggle{@apply flex items-center gap-3 justify-between cursor-pointer;
                    .left_content{@apply flex items-center gap-3;}
                    .counter{@apply w-5 h-5 bg-interactive-100 rounded-full flex items-center justify-center text-center text-11 leading-[10px] font-semibold text-white;}
                }
            }

            .empty_list{@apply text-center py-8;
                .title{@apply text-14 font-semibold;}
                .desc{@apply text-13 text-dark-70 pt-2;}
            }
        
        }
        .popover_footer{@apply  shadow-[0_-1px_0_theme("colors.dark.10")] p-5; }
    }


    &.loading{

        .main_toggle_filter{@apply bg-dark-10 text-dark-50 shadow-none ;}
    }
}
.main_list_capthers{@apply h-[380px] pr-7;
    .main_list_items{@apply pl-7;
        .item_list{@apply flex items-center gap-3  py-3;
            label{@apply items-center;}
            .main_icon{@apply flex;}
            .name{@apply line-clamp-1;}

            &.goal{
                &.active{@apply bg-interactive-10;}
            }
        }

        &[data-stiky]{@apply sticky top-0 bg-white shadow-[inset_0_-1px_0] shadow-dark-10 pl-0 z-[10] ml-7;}
    }
}
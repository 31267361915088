.main_list_status_download_component{@apply px-7;

  .item_status{@apply py-3 items-center flex justify-between shadow-[0_1px_0_theme("colors.dark.10")] last:shadow-none cursor-pointer;
    .left_content{@apply flex items-center gap-4;
      .main_desc{
        .title{@apply font-medium;}
        .desc{@apply text-12 text-dark-70;}
      }
    }
  }
}

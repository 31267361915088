.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.session_popup{
                .main_header_modal{}


                .main_body_modal{

                    .main_message_box{@apply mb-5;}
                }


                .main_footer_modal{@apply grid grid-cols-2;}
            }
        }
    }
}
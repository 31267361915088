.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.email_massive_plan_popup{ max-width: 900px;
                &.form_wizard{max-width: 600px;}
                .main_header_modal{@apply px-7 pt-7;
                
                    .main_action_detail{@apply pt-5;
                        .main_intro{@apply flex items-center gap-2;
                            .main_icon{@apply flex;}
                            .intro{@apply text-12 font-bold text-dark-50 uppercase;}
                        }

                        .name{@apply text-14 pt-2;}
                    }
                }


                .main_body_modal{@apply px-0 py-0;

                    .main_form_email{@apply pt-5 pl-7 pr-7 pb-7;}

                    .change_step_in{ animation: changeIn 250ms forwards ease-in; }

                    .change_step_out{animation: changeIn 250ms forwards ease-in reverse;}

                    @keyframes changeIn {
                        0%{opacity: 0; max-height: 0px; overflow: hidden;}
                        24%{opacity: 0; max-height: 0px; overflow: hidden;}
                        25%{overflow: hidden;}
                        60%{max-height: 330px;}
                        61%{max-height: unset;}
                        100%{opacity: 1;}
                    }
                    
                }

                .main_footer_modal{@apply px-7 pb-7;}
            }
        }
    }
}
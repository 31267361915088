$img_sub: "/images";

// ––––––––––––––––
// Font Family • Montserrat

@mixin montse(){font-family: 'Montserrat', sans-serif;}



// ––––––––––––––––
// Reverse

@mixin reverse(){
  -webkit-transform:rotateY(180deg);
  -moz-transform:rotateY(180deg);
  -o-transform:rotateY(180deg);
  -ms-transform:rotateY(180deg);
  transform:rotateY(180deg);
  unicode-bidi:bidi-override;
  direction:rtl;
}



// ––––––––––––––––
// Color • Opacity

@mixin op($fade:0.5){color: rgba($dark, $fade);
  b{color: $dark;}
}



// ––––––––––––––––––––
// Force long text

@mixin force_text(){overflow-wrap: break-word; word-wrap: break-word; -ms-word-break: break-all; word-break: break-all; word-break: break-word; -ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto; hyphens: auto;}



// ––––––––––––––––––––
// Max width centered

@mixin max($width:500px){max-width: $width; margin: 0 auto;}



// ––––––––––––––––––––
// Transition

@mixin transition($move: all, $time: 0.2s, $effect: ease){
  -webkit-transition: $move $time $effect;
  -moz-transition: $move $time $effect;
  -ms-transition: $move $time $effect;
  -o-transition: $move $time $effect;
  transition: $move $time $effect;
}



// ––––––––––––––––––––
// Dark image

@mixin dark_img($bright:50%){
  -webkit-filter: brightness($bright);
  filter: brightness($bright);
}

@mixin bw($bright:100%){
  -webkit-filter: grayscale($bright); /* Safari 6.0 - 9.0 */
  filter: grayscale($bright);
}





// ––––––––––––––––––––
// Animations

  // Hello modal
  @mixin hello_modal(){animation: hello_modal_content ease-out 0.2s; animation-fill-mode: forwards;
    @keyframes hello_modal_content{
      form{transform: scale(0.9);}
      to{transform: scale(1);}
    }
  }

  // Fade Animation
  @mixin fade($start:0, $finish:1, $time:0.5s){
    @-webkit-keyframes fadeIn{
      from{opacity:$start;}
      to{opacity:$finish;}
    }

    @-moz-keyframes fadeIn{
      from{opacity:$start;}
      to{opacity:$finish;}
    }

    @keyframes fadeIn{
      from{opacity:$start;}
      to{opacity:$finish;}
    }

    -webkit-animation: fadeIn ease-in-out $time;
    -moz-animation: fadeIn ease-in-out $time;
    animation: fadeIn ease-in-out $time;

    -webkit-animation-duration:$time;
    -moz-animation-duration:$time;
    animation-duration:$time;
  }

  // Rotate Animation
  @mixin rotate_animation($start:0deg, $finish:380deg, $time:0.5s){animation: rotate linear $time infinite; animation-fill-mode: forwards;
    @keyframes rotate {
      from {transform: rotate($start);}
      to {transform: rotate($finish);}
    }
  }



// ––––––––––––––––––––
// Before - Absolute & Relative position

@mixin absolute($content:"", $position:absolute){
  content: $content;
  position: $position;
}

@mixin fixed($content:"", $position:fixed){
  content: $content;
  position: $position;
}

@mixin relative($content:"", $position:relative, $display:inline-block){
  content: $content;
  position: $position;
  display: $display;
}



// ––––––––––––––––––––
// Gradient

@mixin gradient($origin: top, $start: #ffffff, $stop: #000000){
  // background-color: $start;
  background-image: -webkit-linear-gradient($origin, $start, $stop);
  background-image: -moz-linear-gradient($origin, $start, $stop);
  background-image: -o-linear-gradient($origin, $start, $stop);
  background-image: -ms-linear-gradient($origin, $start, $stop);
  background-image: linear-gradient($origin, $start, $stop);
}

@mixin gradient_text($origin:right, $color1:#eee, $color2:red){
  background: linear-gradient($origin, $color1, $color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradient_brd($origin: top, $start: #ffffff, $stop: #000000){
  border: 2px solid $white;
  -webkit-border-image: -webkit-linear-gradient($origin, $start, $stop);
  border-image: linear-gradient(to $origin, $start, $stop);
  border-image-slice: 1;
  text-decoration: none;
}



// ––––––––––––––––––––
// Cubo

@mixin cube($igual: 30px){width: $igual; height: $igual;}



// ––––––––––––––––––––
// Truncate

//@mixin truncate($wrap:nowrap, $overflow:hidden, $text-overflow:ellipsis){white-space: $wrap; overflow: $overflow; -ms-text-overflow: $text-overflow; -o-text-overflow: $text-overflow; text-overflow: $text-overflow;}
@mixin truncate ($line-clamp:1, $max-height: unset, $height: auto){
  @if $line-clamp == 1{ white-space: nowrap; display: block;}
  @else{display: -webkit-box; white-space: break-spaces;}
  -webkit-line-clamp: $line-clamp; -webkit-box-orient: vertical; overflow: hidden; -o-text-overflow: ellipsis; text-overflow: ellipsis; max-height: $max-height; height: $height;
}



// ––––––––––––––––––––
// Flip image

@mixin flip(){
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}



// ––––––––––––––––––––
// Align

@mixin mdl($left:-50px, $top:-50px){left: 50%; margin-left: $left; top: 50%; margin-top: $top;}

.mdl_none{left: auto; margin-left: auto; margin-top: auto; top: auto;}

@mixin mdl_top($top:-50px){top: 50%; margin-top: $top;}

@mixin mdl_left($left:-50px){left: 50%; margin-left: $left;}



// ––––––––––––––––––––
// Rotate image

@mixin rotate($deg: 90){
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  transform: rotate($deg);
  -o-transform: rotate($deg);
  /*$IEdeg: round({$deg} / 90, 0);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$IEdeg);*/
}


// ––––––––––––––––––––
// Position absolute

@mixin full($t:0px, $r:0px, $b:0px, $l:0px){top: $t; right: $r; bottom: $b; left: $l;}



// ––––––––––––––––––––
// Borders

@mixin brd($radius: 5px){-webkit-border-radius: $radius; -moz-border-radius: $radius; border-radius: $radius;}

@mixin brd_custom($topleft: 5px, $topright: 5px, $bottomright: 5px, $bottomleft: 5px){
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  -moz-border-radius: $topleft $topright $bottomright $bottomleft;
  border-radius: $topleft $topright $bottomright $bottomleft;
}



// ––––––––––––––––––––
// Prevent hightlight

@mixin no_select(){-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}




// ––––––––––––––––––––
// Icon

@mixin icon($size: 10, $type: '', $directory: 'landing', $extension: 'svg'){
  display: inline-block;
  @include cube($size*1px);
  @if $type == '' {
    background: url("#{$img_sub}/icons/icon-#{$size}.#{$extension}") no-repeat 0 0;
  }@else{
    background: url("#{$img_sub}/icons/icon-#{$size}-#{$type}.#{$extension}") no-repeat 0 0;
  }
  background-size: $size*1px;
  &.icon_mask{
    @if $type == '' {
      mask: url("#{$img_sub}/icons/icon-#{$size}.#{$extension}");
      -webkit-mask-image: url("#{$img_sub}/icons/icon-#{$size}.#{$extension}");
    }@else{
      mask: url("#{$img_sub}/icons/icon-#{$size}-#{$type}.#{$extension}");
      -webkit-mask-image: url("#{$img_sub}/icons/icon-#{$size}-#{$type}.#{$extension}");
    }
    -webkit-mask-size: $size*1px;
    -webkit-mask-repeat: no-repeat;
    background: unset;
  }
}


// ––––––––––––––––––––––
// Icon • bg position

@mixin bg_pos_icon($size, $vt-val, $hz-val:0){
  background-position: $hz-val ($size * -$vt-val) + px;
  -webkit-mask-position: $hz-val ($size * -$vt-val) + px;
}


// ––––––––––––––––––––
// Grid • grid bordered
@mixin grid_bordered($number-column,$gap-size) {
  $val : 0;
  $n : 0;
  @while $n < $number-column {
    @if $val == 0 {
      $val : calc(100%/#{$number-column} - #{$gap-size});
    }@else{
      $val : $val + calc(100%/#{$number-column} - #{$gap-size});
    }
    $n : $n + 1;
  }
  grid-template-columns: $val;
}



@mixin bg-phone($bg-image : 0){
  @if $bg-image != 0{ background: url("#{$img_sub}/landing/shared/phone-template.svg"),url("#{$bg-image}");}
  @else { background: url("#{$img_sub}/landing/shared/phone-template.svg");}
}



// –––––––––––
// Font family

@mixin font ($font-family){
  @if $font-family == "secondary"{
    font-family: 'Montserrat Alternates', sans-serif;
  } @else {
    font-family: $font-family;
  }
}

// –––––––––––
// Font feature settings

@mixin font_feature ($feature, $off : 0){
  @if $off == 0 {
    font-feature-settings: $feature on;
  } @else {
    font-feature-settings: $feature off;
  }
}
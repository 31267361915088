.main_add_item{@apply rounded-[6px] min-h-[80px] flex bg-interactive-100/5 p-3 cursor-pointer hover:bg-interactive-100/10;

    &.small{min-height: 55px;}
                
    .wrap_add_item{@apply rounded-[2px] border border-interactive-100 border-dashed w-full flex justify-center items-center;
    
        .main_icon{@apply shadow-[inset_0_0_0_2px_theme("colors.white"),0_1px_2px_theme("colors.dark.100")1a] p-2 rounded-full flex;}

        .label{@apply text-interactive-100 text-12;}
    }


    &.in_line{@apply h-2 min-h-[2px] p-0 z-10 bg-transparent relative rounded-[2px] mb-2 shadow-none opacity-0 hover:opacity-100 transition-all;
   
        .wrap_add_item{@apply border-none shadow-[0_1px_1px_theme("colors.dark.100")1a] bg-white;
            
            .main_icon{@apply bg-interactive-10 absolute;}
        }

        &:hover{@apply min-h-[12px] py-[4px] h-4;}

        &.chapter{@apply h-3 min-h-[8px] mb-[6px] bg-primary-5 shadow-[0_1px_2px_theme("colors.dark.100")1a] transition-all p-2;
            .wrap_add_item{@apply bg-transparent shadow-none;
                .main_icon{@apply bg-opacity-0 shadow-none;}
            }

            &:hover:not(.disable_hover){@apply min-h-[36px] h-11;}
        }
    }

    &.only_icon{ @apply min-h-[unset] bg-transparent w-fit p-0;
        .wrap_add_item{@apply border-none;
            .main_icon{@apply bg-interactive-10 hover:bg-interactive-20 p-3;}
        }
    }
}
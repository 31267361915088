.main_list_content{
    .main_item{@apply flex gap-3 py-3 pl-7 pr-9;
        &.item_collapse{@apply cursor-pointer hover:bg-primary-5;}
        .main_icon{@apply flex mt-1;}
        .name{@apply line-clamp-1;}
    }

    .main_list_goals{
        .main_item{@apply flex gap-3 py-3 pl-12 pr-9;}
        .main_list_actions{@apply pl-12;}
    
    }
}
.main_card_annotation{@apply rounded-[2px] shadow-[0_1px_1px_theme("colors.dark.100")0d] bg-white p-5 cursor-pointer relative overflow-hidden;
    
    &:hover{
        .main_intro{
            .main_actions{@apply flex;}
        }

    }
    
    .main_intro{@apply flex items-center gap-2 justify-between;
        .wrap_intro{@apply flex items-center gap-2 max-w-full overflow-hidden;
            .main_icon{@apply flex flex-none;}
            .title{@apply text-dark-50 uppercase text-12 font-bold truncate;}
        }

        .main_actions{@apply items-center gap-2 hidden flex-none;
            .main_action{@apply flex p-[1px] rounded-[2px] hover:bg-interactive-10;}
        }
    }

    .main_desc{@apply pt-2 pl-1;
        .desc{@apply text-14 line-clamp-2;}
    }

    &.active{
        &::before{@apply content-[""] absolute left-0 top-0 h-full w-[6px] bg-primary-100;}
    }
}
.main_notification{@apply fixed bottom-11 right-10 pl-6 py-6 pr-10 shadow-[0_0_0_1px_theme("colors.dark.10"),0_4px_16px_theme("colors.dark.100")0d] rounded-[4px] w-[380px] z-[1000] bg-white;
    &.error{@apply shadow-[0_0_0_1px_theme("colors.red.100"),0_5px_15px_theme("colors.dark.100")1a] bg-red-10;}
    &.success{@apply shadow-[0_0_0_1px_theme("colors.success.50"),0_3px_10px_theme("colors.dark.100")1a] bg-success-10;}

    .main_close{@apply absolute top-4 right-4 cursor-pointer hover:opacity-70;}

    .main_title{@apply flex gap-3; 
        .main_icon{@apply flex mt-1;}
        .title{@apply text-14 font-medium ;}

        .main_list_field{@apply list-disc pl-5 pt-2 text-dark-100/70;
            .field_error{@apply text-13;}
        }
    }
    .main_actions{@apply pt-4;}

    &.notification_in{animation: notificationIn 200ms forwards ease-in;}

    &.notification_out{animation: notificationIn 200ms forwards ease-in reverse;}

    @keyframes notificationIn {
        0%{opacity: 0; transform: translateX(20px);}
        100%{opacity: 1; transform: translateX(0);}
    }

}
@layer components{

    .main_comp_table{@apply shadow-[0_1px_0_theme("colors.dark.10")] min-w-full;

        .main_table_head{@apply shadow-[0_1px_0_theme("colors.dark.10")] relative;
            .main_head_item{@apply text-12 px-3 py-5 text-left font-bold whitespace-nowrap uppercase text-dark-50 last:text-right ;}

            &.sticky_head{@apply sticky top-0 bg-white z-10;}
        }

        .main_table_body{/*@apply divide-y divide-dark-10;*/

            .main_table_row{@apply last:rounded-b-[8px] text-dark-100 border-b border-b-dark-10 last:border-0;
                .main_table_item{@apply px-3 py-5 text-14 text-dark-100 first:pl-4 last:pr-4 last:text-right;

                    .title{@apply text-14 font-normal;}
                    .subtitle{@apply text-13 font-normal pt-1 opacity-70;}
                    .tag{@apply text-12 font-bold px-3 rounded-full py-[3px] bg-primary-10;}


                    .progress_item{@apply flex items-center gap-3;

                        .progress_bar{@apply w-[120px] bg-dark-10 rounded-full h-[5px] relative;
                            .progress_value{@apply absolute left-0 h-full rounded-full bg-secondary-100;}
                        }

                        .value{@apply text-13 text-dark-70 leading-[1em] font-medium;}
                    }

                    .name_logo{@apply flex items-center gap-3;
                        .logo{@apply w-7 h-7 flex-none object-contain;}
                        .main_icon{@apply flex flex-none p-2;}
                        .main_cover{@apply p-[7px] rounded-[3px] flex-none;}
                    }

                    .main_icon_text{@apply flex items-center text-14 leading-[1em] font-medium gap-2;}

                    .spacing_items{@apply flex items-center gap-4;}

                    .status_grid{@apply grid grid-cols-7 gap-1;
                        [class*="icon_"]{@apply flex-none;}
                    }

                    &:last-child{
                        .spacing_items{@apply justify-end;}
                    }

                    .action_list{@apply flex items-center gap-2;
                        .action{@apply flex p-2 rounded-[4px] hover:bg-interactive-10;}
                    }
                }

                &:last-child{
                    .main_table_item{@apply first:rounded-bl-[8px] last:rounded-br-[8px] text-dark-100;}
                }

                &.clickable{@apply cursor-pointer;
                    &:hover{
                        .main_table_item{@apply bg-dark-10;}
                    }
                    &:active{
                        .main_table_item{@apply bg-dark-10;}
                    }
                }

                &.active{
                    .main_table_item{@apply bg-yellow-10;}

                    &:hover{
                        .main_table_item{@apply bg-yellow-10;}
                    }
                    &:active{
                        .main_table_item{@apply bg-yellow-10;}
                    }
                }
            }

            .action_hover_list{
                .action_list{@apply opacity-0 transition-opacity;}

                &:hover{
                    .action_list{@apply opacity-100;}
                }
            }
        }


        &.layout_popup{
            .main_table_head{
                .main_head_item{@apply py-2;}
            }


            .main_table_body{
                .main_table_row{
                    .main_table_item{@apply py-3;}
                }
            }

            &.big_row{
                .main_table_body{
                    .main_table_row{
                        .main_table_item{@apply py-4;}
                    }
                }
            }
        }
    }
}

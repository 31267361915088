.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.plan_download_popup{ max-width: 650px;
                &.corps_wizard{max-width: 900px}
                .main_header_modal{@apply shadow-[0_1px_0_theme("colors.dark.10")] p-7 gap-5;
                
                }


                .main_body_modal{@apply px-0 py-0;
                    
                    .main_list_status_reqs{@apply px-7;

                        .item_status{@apply py-3 items-center flex justify-between shadow-[0_1px_0_theme("colors.dark.10")] last:shadow-none cursor-pointer;
                            .left_content{@apply flex items-center gap-4;
                                .main_desc{
                                    .title{@apply font-medium;}
                                    .desc{@apply text-12 text-dark-70;}
                                }
                            }
                        }
                    }

                    .change_step_in{ animation: changeIn 250ms forwards ease-in; }

                    .change_step_out{animation: changeIn 250ms forwards ease-in reverse;}

                    @keyframes changeIn {
                        0%{opacity: 0; max-height: 0px; overflow: hidden;}
                        24%{opacity: 0; max-height: 0px; overflow: hidden;}
                        25%{overflow: hidden;}
                        60%{max-height: 330px;}
                        61%{max-height: unset;}
                        100%{opacity: 1;}
                    }
                }

                .main_footer_modal{@apply shadow-[0_-1px_0_theme("colors.dark.10")] p-7;}
            }
        }
    }
}
// ––––––
// Check

@layer components {

	.check_landing{@apply inline-block relative select-none;

		label{@apply inline-block relative text-14 pl-[28px] min-h-[20px] leading-[20px] align-top cursor-pointer overflow-hidden;

			&.icon{@apply inline-flex gap-3;
				.main_icon{@apply flex mt-1;}
			}

			&:before{@apply absolute content-[""] w-6 h-6 left-0 shadow-[inset_0_0_0_1px_theme("colors.dark.30")] rounded-[2px] bg-white;}

			&:after{@apply absolute content-[""] w-6 h-6 left-0 top-0 bg-[20px] bg-transparent;}

			&:hover{
				&:before{@apply shadow-[inset_0_0_0_1px_theme("colors.dark.50")] absolute;}
			}
		}

		&.dis{
			label{@apply pointer-events-none text-dark-30;
				&:before{@apply shadow-[inset_0_0_0_1px_theme("colors.dark.10")] bg-dark-10 text-dark-20;}
			}
		}

		&.err{
			label{
				&:before{@apply shadow-[inset_0_0_0_1px_theme("colors.red.100")] absolute;}
			}
		}

		&.only_toggle{
			label{@apply pl-6;}
		}


		// Checked
		input[type=checkbox]:checked + label{

			&:before{ @apply shadow-[inset_0_0_0_2px_theme("colors.interactive.100")] bg-interactive-100 ;}
			&:after{
				@apply w-[10px] h-[10px]  animate-[onCheck_ease-in-out_0.4s_forwards] bg-[url("./images/icons/icon-10-checkbox.svg")] top-[10px] left-[5px] rounded-[2px] bg-[0_0] z-[2] bg-no-repeat; background-size: 10px;
			}


			@keyframes onCheck{
				0%{top: 6px; opacity: 0;}
				50%{top: 1px; opacity: .8;}
				100%{top: 5px; opacity: 1;}
			}
		}


		&.small{
			label{@apply pl-7;
				&:before{@apply w-[14px] h-[14px] top-[3px] absolute;}

				&.only_toggle{padding-left: 14px;}
			}

			// Checked
			input[type=checkbox]:checked + label{

				&:before{ @apply shadow-[inset_0_0_0_2px_theme("colors.interactive.100")] bg-interactive-100 ;}
				&:after{
					@apply bg-[url("./images/icons/icon-10-checkbox.svg")] bg-[0_-20px] left-1;
				}
			}
		}


		&.partial{
			label{
				&:before{@apply shadow-none bg-interactive-100;}
				&:after{
					@apply w-[10px] h-[10px] top-[5px] bg-[url("./images/icons/icon-10-checkbox.svg")] left-[5px] rounded-[2px] bg-[0_-10px] z-[2] bg-no-repeat; background-size: 10px;
				}
			}

			&.small{
				label{
					&:after{
						@apply bg-[url("./images/icons/icon-10-checkbox.svg")] bg-[0_-30px] left-1;
					}
				}
			}
		}
	}


	.toggle_landing{@apply z-0 select-none text-dark-80;

		label{@apply relative pl-[43px] align-top leading-[16px] cursor-pointer min-h-[16px] inline-block;

			&.only_toggle{@apply pl-[32px] relative;}

			// Thumb
			&:before {@apply content-[""] absolute w-[14px] h-[14px] left-[1px] top-[1px] rounded-full z-[1] bg-white shadow-[0_2px_6px_theme("colors.dark.100")33] transition-all;
			}

			// Track
			&:after{@apply bg-no-repeat content-[""] absolute w-[32px] h-[16px] left-0 top-0 rounded-[16px] bg-dark-40; /* background-image: url("../images/icons/icon-25-toggle.svg"); */ background-size: 24px; background-position: 14px -24px;
			}

			&:hover{@apply text-dark-100;}

			&:active{@apply text-dark-100;
				&:after{@apply shadow-[0_0_0_2px_theme("colors.primary.40")] bg-dark-50;}
			}
		}

		&.err{
			label{
				&:after{@apply shadow-[inset_0_0_0_1px_theme("colors.red.100"),inset_0_2px_2px_theme("colors.dark.10")] absolute;}
			}
		}

		&.dis{ @apply opacity-30 select-none ;}

		&.only_toggle{
			label{@apply pl-[44px] relative;}
		}


		// Checked
		input[type=checkbox]:checked + label{@apply text-dark-100;


			// Thumb
			&:before{@apply left-[17px] absolute;}

			// Track
			&:after{@apply bg-interactive-100 bg-no-repeat; /* background-image: url("../images/icons/icon-25-toggle.svg"); */ background-size: 24px; background-position: 3px 0;}

			&:hover{
				&::after{@apply bg-interactive-80;}
			}

			&:active{
				&::after{@apply bg-interactive-100;}
			}
		}
	}
}

// –––––––––
// Textarea

// Use min-height for autosize height of textarea

textarea{@apply  rounded-[4px] resize-none; min-height: 60px !important; display: block;
  &.small{ min-height: 32px !important;}
  &.big_text{ min-height: 136px !important;}
}


.wrap_textarea_landing{position: relative;
  textarea.landing{@apply block overflow-y-auto;}
  // &.floating{
  //   textarea{
  //     &:focus::placeholder, &::placeholder{color: transparent;}
  //     &:focus::-ms-input-placeholder, &::-ms-input-placeholder{color: transparent;}

  //     // Error
  //     &.err{box-shadow: inset 0 0 0 1px $red!important;
  //       /* ~ .placeholder{color: $red2!important;} */
  //       ~ .placeholder ~ .info{display: flex!important;}
  //       ~ .placeholder ~ .icon_error{display: block!important;}
  //     }
  //     // Input • focus • float placeholder
  //     &:focus ~ .placeholder, &.small:focus ~ .placeholder,
  //     &:not(:placeholder-shown) ~ .placeholder{top: -8px; left: 9px; padding: 0 9px; background: #fff; font-size: 13px; font-weight: 700; line-height: 16px; color: rgba($dark, 0.3); opacity: 1; @include brd(3px);}
  //     &:focus ~ .placeholder{color: $blue;}
  //   }
  //   .info{display: none; cursor: pointer; @include cube(16px); position: absolute; top: calc(50% - 8px); right: - 7.5px; align-items: center; justify-content: center; @include brd(50%); background: $white; color: $secondary; font-style: unset; font-size: 10px; font-weight: 900; z-index: 9;}
  //   .placeholder{position: absolute; pointer-events: none; left: 15px; top: 11px; font-size: 15px; transition: 0.2s ease all; color: rgba($dark, 0.3); z-index: 9;}
  //   .small ~ .placeholder{top: 9px;}

  //   // Width • 580px
	// 	@media screen and(max-width : 580px) {
	// 		.placeholder{ font-size: 13px;}

	// 		textarea{
	// 			&:focus ~ .placeholder, &.small:focus ~ .placeholder,
	// 			&:not(:placeholder-shown) ~ .placeholder{font-size: 10px; }
	// 		}
	// 	}	
  // }
}

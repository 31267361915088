.main_fitlers{@apply flex gap-6 /* pb-3 */;

    .main_reset{@apply flex items-center hover:opacity-70 font-bold text-interactive-100 gap-2 cursor-pointer;
        .main_icon{@apply flex;}
    }
}

.main_intro{@apply flex justify-between pb-3; 
    .left_content{
        .title{@apply text-18 font-bold;}
    }

    .right_content{@apply flex gap-5;}
}
.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.confirm_popup{
                .main_header_modal{}


                .main_body_modal{@apply pb-7;
                
                    .main_subtitle{@apply flex gap-2 items-center;
                        .main_icon{@apply flex;}
                        .subtitle{@apply uppercase text-12 font-bold text-dark-50;}
                    }

                    .main_description{@apply pt-[6px] text-14;}

                    .main_counter_container{@apply flex items-center pt-[6px] gap-3;
                        .main_counter{@apply flex gap-2 items-center font-medium text-13;
                            .main_icon{@apply flex;}
                        }
                    }

                    .main_alert_message{@apply px-4 py-[10px] rounded-[4px] shadow-[inset_1px_-1px_0_theme("colors.danger.100")] flex mt-6 bg-danger-10 gap-3 first:mt-0;
                        .main_icon{@apply flex mt-1 flex-none;}
                        .message{@apply text-12;}
                    }
                }


                .main_footer_modal{@apply grid grid-cols-2;}
            }
        }
    }
}
@layer components {

	input{ border-radius: 4px;
		&::-webkit-inner-adjust-hue-button, &::-webkit-clear-button{display:none;}
		&::-webkit-calendar-picker-indicator{ @apply text-14 p-0 m-0 text-transparent absolute right-0 left-auto cursor-pointer top-0 bottom-0 w-full h-full;
			background: transparent;
			&:hover{background: transparent;}
		}
	}

	input:has(+ .float_placeholder){background: #000;}


	// Input • date • placeholder
	input[type="date"]:before {content: attr(placeholder); @apply text-dark-50;}
	input[type="date"]:focus:before, input[type="date"]:valid:before{display: none;}
	input[type="date"]:not([value=""]):before{display: none;}


	// Input • number
	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button{-webkit-appearance: none; margin: 0;}
	input[type=number]{-moz-appearance:textfield;}


	.wrap_input_landing{position: relative;
		&.floating{
			input{
				&:focus::placeholder, &::placeholder{color: transparent;}
				&:focus::-ms-input-placeholder, &::-ms-input-placeholder{color: transparent;}

				// Input • focus • float placeholder
				&:focus ~ .placeholder, &.small:focus ~ .placeholder,
				&:not(:placeholder-shown) ~ .placeholder{top: -8px; left: 9px; padding: 0 9px; background: #fff; font-size: 13px; font-weight: 500; line-height: 16px; opacity: 1; font-weight: 700; @apply text-dark-30;}
				&:focus ~ .placeholder{@apply text-primary-100;}

				// Error
				&.err{@apply shadow-[inset_0_0_0_1px_theme("colors.red.100")] relative; padding-right: 35px ;
					~ .placeholder{@apply text-red-100;}
					~ .placeholder ~ .info{display: flex;}
					~ .placeholder ~ .icon_error{display: block;}
				}
			}
			
			.icon_error{display: none; cursor: pointer; width: 20px; height: 20px; position: absolute; top: calc(50% - 10px); right: 10px; align-items: center; justify-content: center; z-index: 9;}
			.info{display: none; cursor: pointer; width: 16px; height: 16px; position: absolute; top: calc(50% - 8px); right: - 7.5px; align-items: center; justify-content: center; border-radius: 50%; font-style: unset; font-size: 10px; font-weight: 900; z-index: 9; @apply bg-white text-blue-100;}
			.placeholder{position: absolute; pointer-events: none; left: 15px; top: 12px; font-size: 15px; transition: 0.2s ease all; z-index: 9; @apply text-dark-30;}
			.small ~ .placeholder{top: 9px;}
			.big ~ .placeholder{left: 20px; top: 20px;}

			&.icon{
				.placeholder{left: 45px;}
			}
		}

		&.icon{
			[class*="icon"]{position: absolute; display: block; left: 8.5px; top: 50%; transform: translateY(-50%); z-index: 4; pointer-events: none; cursor: pointer; opacity: .3;}
			input{padding-left: 32px;
				&.big, &.small{ padding-left: 40px;}
				&:focus~[class*="icon"]{opacity: .7;}
			}
		}

		&.icon_right{
			.icon_right{position: absolute; display: block; left: unset; right: 8.5px; top: 50%; transform: translateY(-50%); z-index: 4; pointer-events: none; cursor: pointer; opacity: .3;}
			input{padding-right: 32px;
				&.big, &.small{ padding-left: 40px;}
				&:focus~[class*="icon"]{opacity: .7;}
			}
		}

		&.number{position: relative;
			input.landing{padding-right: 30px;
				&.percentage{width: 85px;}
			}
			.arrow_up{position: absolute; top: 20%; right: 8px; z-index: 10; cursor: pointer;}
			.arrow_down{position: absolute; bottom: 20%; right: 8px; z-index: 10; cursor: pointer;}

			&.icon_currency{
				input.landing{padding-left: 30px}
				.main_icon{@apply absolute top-1/2 -translate-y-1/2 left-3 flex opacity-40 z-10 pointer-events-none;}
			}

			&.no_arrows{
				input.landing{padding-right: 10px;} 
			}
		}

		&.remove{display: flex; align-items: center;
			input{display: inline-flex;}
			.wrap_btn{display: inline-flex;
				[class*="icon_20"]{cursor: pointer; opacity: .3;
					&:hover{opacity: .7;}
				}
			}
		}


		&.date{
			input[type="date"], [type="time"], [type="week"], [type="month"]{ @apply pr-[38px] relative pt-2;
			
				&.dis + [class*="icon_24"]{ @apply grayscale;}

				&.big{@apply pt-3;}
			}
			[class*="icon_24"]{@apply absolute inline-block pointer-events-none right-[10px] top-1/2 -translate-y-1/2 z-[4] cursor-pointer;}
		}
	}
}
// –––––––
// Buttons
@layer components {
	
	.button { @apply inline-flex items-center justify-center rounded-[4px] py-3 pt-[10px] px-7 text-14 font-bold gap-[6px] min-h-[40px] cursor-pointer select-none;
        &.big{ @apply py-3 min-h-[56px] pt-[15px] text-18;}

        &.small{ @apply pt-[6px] pb-[5px] min-h-[32px] gap-2 px-5; }

        &.x_small{ @apply pt-[3px] pb-[3px] text-12 min-h-[24px] px-4 gap-2; }

        &.fill{@apply active:shadow-[0_0_0_3px_theme("colors.primary.40")] focus:shadow-[0_0_0_3px_theme("colors.interactive.70")] outline-none; 

            &.primary{ @apply bg-primary-100 text-white hover:bg-primary-90 
                active:bg-primary-110 active:text-opacity-70
                focus:bg-primary-100
                ;}

            &.white{ @apply bg-white text-dark-60 hover:bg-dark-5 
                active:bg-white active:text-dark-80
                focus:text-dark-100
                ;}

            &.secondary{ @apply bg-primary-10 text-primary-100 hover:bg-primary-20 
                active:bg-primary-10 active:text-opacity-70
                focus:bg-primary-10
                ;}

            &.tertiary{ @apply bg-dark-60 text-white hover:bg-dark-70 
                active:bg-dark-60 active:text-opacity-70
                focus:bg-dark-60
                ;}

            &.interactive{ @apply bg-interactive-100 text-white hover:bg-interactive-80 
                active:bg-interactive-100 active:text-opacity-70
                focus:bg-interactive-100
                ;}

            &.error{@apply bg-red-100 text-white hover:bg-red-90 
                active:bg-red-100 active:text-opacity-70
                focus:bg-red-100
                ;}

            &.success{@apply bg-success-100 text-white hover:bg-success-90 
                active:bg-success-100 active:text-opacity-70
                focus:bg-success-100
                ;}

            &.ligth_green{@apply bg-green-30 text-success-100 hover:bg-green-50 
                active:bg-green-30 active:text-opacity-70
                focus:bg-green-30
                ;}

            &.ligth_red{@apply bg-red-20 text-red-90 hover:bg-red-30 
                active:bg-red-20 active:text-opacity-70
                focus:bg-red-20
                ;}

            &.danger{@apply bg-danger-100 text-white hover:bg-danger-90 
                active:bg-danger-100 active:text-opacity-70
                focus:bg-danger-100
                ;}

        }

        &.disabled{@apply pointer-events-none opacity-30;}


        &.line{@apply  bg-white text-dark-70 shadow-[inset_0_0_0_1px_theme('colors.dark.30')]
            hover:shadow-[inset_0_0_0_1px_theme('colors.dark.100')] hover:text-dark-100
            active:shadow-[inset_0_0_0_1px_theme('colors.dark.100'),0_0_0_3px_theme("colors.primary.40")] active:text-dark-70
            focus:shadow-[inset_0_0_0_1px_theme('colors.dark.100'),0_0_0_3px_theme("colors.interactive.100")] focus:text-dark-70
            ;
        }


        .main_loader_icon{@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden;
            width: 16px; 
            height: 16px; 
            background-size: 16px; 
            mask: url("#{$img_sub}/icons/loader-line.svg");
            -webkit-mask-image: url("#{$img_sub}/icons/loader-line.svg");
            -webkit-mask-size: 16px;
            -webkit-mask-repeat: no-repeat;
            background: #fff;
        }

        .load_content{@apply inline-flex items-center justify-center;}

        &.loading{@apply relative;
            .load_content{@apply opacity-0;}
            .main_loader_icon{@apply block;}
        }
	}


    .group_buttons{@apply flex items-center;
        .button{@apply first:rounded-l-[6px] last:rounded-r-[6px] rounded-none;}
    }
}
.main_card_chapter__container{@apply rounded-[6px] bg-primary-5 mb-[6px] transition-[background,box-shadow] duration-500 block relative overflow-hidden;
    
    &.view_mode{@apply mb-5;}
    
    // Toggle
    .main_card_chapter__toggle{@apply pl-5 pr-7 py-5 cursor-pointer;
        
        // Heading Toggle
        .main_heading_toggle{@apply flex gap-2 justify-between;

            // Icon
            .main_icon{@apply p-[3px] flex;
                &.draggable{@apply shadow-[0_0_1px_theme("colors.dark.100")1a,0_1px_2px_theme("colors.dark.100")1a] rounded-[2px] hidden bg-white h-fit;}
            }
    
            // Title
            .main_title{@apply w-full max-w-full overflow-hidden;
                .main_subtitle{@apply flex justify-between w-full gap-3;}
                .subtitle{@apply text-dark-50 uppercase font-bold text-12 truncate;}
                .title{@apply font-bold text-14 line-clamp-2;}
            }

            // Actions
            .main_actions{@apply items-center gap-2 hidden flex-none;}

        }

        &:hover{

            // Actions
            .main_actions{@apply flex;}
        }


        // Summary Container
        .main_summary{@apply flex pl-5 pt-3 gap-5;

            // Item Sum
            .item_sum{@apply flex items-center gap-2;
                [class*="icon"]{@apply flex-none;}
                .value{@apply leading-[1em] text-13 font-semibold text-dark-70;}
            }
        }

        &:not(.not_draggable):hover{
            
            // Heading Toggle
            .main_heading_toggle{

                // Icon
                .main_icon{
                    &.type{@apply hidden;}
                    &.draggable{@apply flex;}
                }
            }
        }
    }


    // Chapter Content
    .main_card_chapter__content{@apply px-9 pb-6;
        .wrap_card_chapter__content{@apply mb-4 pb-[1px] shadow-[0_1px_theme("colors.dark.100")1a] last:shadow-none last:pb-0 last:mb-0;}
    }

    // Dragging layout
    &.is_dragging{@apply shadow-[0_2px_4px_theme("colors.dark.100")0D] bg-primary-10;}


    // Active layout
    &.active{@apply bg-primary-10;

        // Toggle
        .main_card_chapter__toggle.closed{
            &::before{@apply content-[""] absolute left-0 top-0 h-full w-[6px] bg-primary-100;}
        }
    }

    &.disabled{@apply pointer-events-none opacity-40 grayscale-0;}
}
.main_header{@apply h-[64px] relative;

    .wrap_header{@apply px-9 shadow-[inset_0_-1px_0_theme("colors.dark.10")] fixed w-full bg-white h-[64px] min-w-[980px] z-30;
        
        .main_header_content{@apply py-5 flex justify-between items-center relative;

            .left_content{@apply flex items-center gap-5;
                .logo{@apply h-9;}
                .main_name{@apply flex shadow-[-1px_0_theme("colors.dark.10")] pl-5 gap-2;
                    .name{@apply text-12 font-medium text-dark-70;}
                }
            }
    
            .nav_content{@apply absolute left-1/2 -translate-x-1/2;
                .list_nav{@apply flex items-center;}
                .nav_item{@apply text-14 font-medium px-5 py-[6px] rounded-full text-dark-70 hover:text-dark-100 flex items-center gap-2;
                    .main_icon{@apply flex;}
                    &.active{@apply bg-primary-10 text-primary-100;}
                }
            }
        }


        &.with_subheader{@apply z-[31] shadow-none;
            .main_header_content{@apply shadow-[inset_0_-1px_0_theme("colors.dark.10")] flex;}
        }
    }
}
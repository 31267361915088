@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


html{min-width: 950px;}

body {min-width: 950px; margin: 0; font-family: 'DM Sans', sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-size: 14px; @apply text-dark-100; background: #F6F7F7; letter-spacing: -.01em;}

*{outline: none !important;
	outline: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

input[type=radio], [type=checkbox] {display: none;}


input, textarea, label, select, button{
	appearance:none;
	-moz-appearance:none;
	-webkit-appearance:none;

	&:invalid {box-shadow: none;}
	&:-moz-submit-invalid {box-shadow: none;}
	&:-moz-ui-invalid {box-shadow:none;}

	// Remove autocomplete background
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active{background: initial;}
}





@import "./theme/mixins";
@import "./theme/icons";
@import "./theme/checkbox.scss";
@import "./theme/radiobutton.scss";
@import "./theme/buttons.scss";
@import "./theme/global-style-form.scss";
@import "./theme/textinput.scss";
@import "./theme/modal.scss";
@import "./theme/tables.scss";
@import "./theme/form.scss";
@import "./theme/select.scss";
@import "./theme/textarea.scss";
@import "./theme/page-layout.scss";
@import "./theme/rangeinput.scss";

body{

	.tooltipster{@apply bg-dark-100 text-white rounded-[8px] shadow-[0_4px_12px_theme("colors.dark.100")1a] py-3 px-4 text-12 font-semibold max-w-[300px] text-center opacity-100 z-[999] break-words whitespace-pre-wrap;
		&.small{@apply py-[6px] px-[10px] text-11;}
	}
	
	.tooltipster_arrow{@apply rounded-br-[2px] bg-dark-100;
		&.right{@apply rounded-bl-[2px] rounded-br-none ;}
		&.bottom{@apply rounded-tl-[2px] rounded-br-none ;}
	}
}



// ––––––––––––
// Fade In/Out

.fade_in{animation: fadeIn 200ms linear; animation-fill-mode: forwards;}
.fade_out{animation: fadeIn 200ms linear; animation-fill-mode: revert;}


@keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

[data-overlayscrollbars-contents] {
	@apply overscroll-contain;
}


.collapse_in{ animation: collapseIn 150ms forwards ease-in;}

.collapse_out{ animation: collapseIn 150ms forwards ease-in reverse;}


.popover_in{ animation: popoverIn 200ms forwards ease-in;}

.popover_out{ animation: popoverIn 200ms forwards ease-in reverse;}

@keyframes popoverIn {
    0%{opacity: 0; transform: translateY(-3px);}
    100%{opacity: 1; transform: translateY(0);}
}

@keyframes collapseIn {
    0%{ max-height: 0px; overflow: hidden;}
    100%{ max-height: 500px; overflow: hidden;}
}

.wizard_step_in{ animation: wizardStepIn 250ms forwards ease-in; }

.wizard_step_out{animation: wizardStepIn 250ms forwards ease-in reverse;}

@keyframes wizardStepIn {
	0%{opacity: 0; max-height: 0px; overflow: hidden;}
	24%{opacity: 0; max-height: 0px; overflow: hidden;}
	25%{overflow: hidden;}
	60%{max-height: 330px;}
	61%{max-height: unset;}
	100%{opacity: 1;}
}
.main_box{
    .list_notes{@apply grid gap-5;
        .item_note{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[4px] px-7 py-5 flex items-center justify-between gap-4;
            .left_content{@apply flex items-center gap-4;
                .main_icon{@apply flex;}
                .main_name{@apply max-w-full;
                    .title{@apply text-16 font-bold line-clamp-1;}
                    .subtitle{@apply text-14 text-dark-70 line-clamp-1;}
                }
            }

            .right_content{@apply flex;}
        }
    }
}
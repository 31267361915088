@layer components{

    .layout_sidebar{min-width: 980px; @apply flex flex-col min-h-screen;}



    // Page Box layout
    .main_page_box{@apply p-9;

        &.grid_layout{@apply grid gap-5;}

        .grid_page_right_side{@apply flex w-full gap-7;
            .left_content{@apply w-0 flex-1 flex flex-col gap-5;}
            .right_content{@apply max-w-[450px] w-1/3 flex flex-col gap-5;}
        }
    }






    // Page sidebar Layout
    .main_page_sidebar{margin-left: 450px; @apply flex-1 flex flex-col;
        .wrap_page_sidebar{@apply p-7 pr-9 pb-11 flex flex-col flex-1;
            &.block_scroll{@apply max-h-[calc(100vh_-_128px)] overflow-hidden;}
        }
        
        &.white_page{@apply min-h-[calc(100vh_-_64px)] bg-white;
            .wrap_page_sidebar{@apply px-0;}
        }
    }






    // Comp actions group
    .main_comp_actions_group{@apply flex items-center gap-5;
        .main_list_status_container{@apply flex gap-3 items-center;}

        .main_list_counter{@apply flex gap-3;}

        .main_actions{@apply flex items-center gap-3 shadow-[-1px_0_0_theme("colors.dark.10")] pl-4;}

        .main_counter{@apply flex gap-2 items-center;
            .main_icon{@apply flex;}
        }
    }





    // Comp status action
    .main_comp_status_action{@apply flex rounded-[2px] bg-dark-5 cursor-pointer hover:bg-dark-10 ;
        .icon.icon_16.icon_mask{@apply bg-dark-50;}
    
        &.active{@apply bg-secondary-100;
            .icon_16.icon_mask{@apply bg-white;}
        }
    }





    // Comp action btn
    .main_comp_action_btn{@apply flex p-[1px] rounded-[2px] hover:bg-interactive-100/10 cursor-pointer;
        &.disabled{@apply opacity-40 hover:bg-transparent;}
    }





    // Comp icon desc
    .main_comp_icon_desc{@apply flex flex-none;}




    .main_comp_dropdow_container{@apply relative;
        .main_comp_dropdow_content{@apply absolute z-10 mt-4 left-0;
            &.right_ancor{@apply left-[unset] right-0;}
        }
    }

    .main_comp_loader_box_icon{
        width: 30px; 
        height: 30px; 
        background-size: 30px; 
        mask: url("#{$img_sub}/icons/loader-line.svg");
        -webkit-mask-image: url("#{$img_sub}/icons/loader-line.svg");
        -webkit-mask-size: 30px;
        -webkit-mask-repeat: no-repeat;
        @apply bg-dark-100;
    }


    .main_comp_dropdown_cotent_generic{@apply shadow-[0_0_0_1px_theme("colors.dark.10"),0_8px_20px_theme("colors.dark.100")1a] rounded-[10px] min-w-[220px] w-max bg-white overflow-hidden;
        .main_list_options{@apply grid divide-y divide-dark-10;
            .item_option{@apply text-14 text-dark-100 p-[10px_15px] hover:bg-interactive-10 cursor-pointer flex justify-between gap-3 items-center;
                .main_icon{@apply flex flex-none;}
            }
        }
    }



    .main_comp_organizations_picker{@apply px-7 pt-5 pb-7 flex h-[500px] gap-7;
        .main_list_entities{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[8px] flex-1 flex flex-col;
                    

            .main_heading_list{@apply p-5 pb-6;
                .main_intro{@apply flex justify-between items-center;
                    .title{@apply text-16 font-bold;}
                }

                .main_filters{@apply flex justify-between gap-5;}
            }

            .main_table_entity{@apply max-h-full pb-5 px-5;}
        }

        .main_list_selected{@apply w-1/3 shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[8px] bg-light-gray flex flex-col;
            .main_counter{@apply shadow-[0_1px_0_theme("colors.dark.10")] p-5;
                .title{@apply text-16 text-dark-70 font-medium;
                    b{@apply text-dark-100 font-bold;}
                }
            }

            .list_container{@apply max-h-full flex overflow-hidden;
                .main_list_items{@apply p-5 w-full;
                    .wrap_list_items{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[4px] bg-white overflow-hidden;
                        .item_entity{@apply py-[5px] px-3 bg-white shadow-[inset_0_-1px_0_theme("colors.dark.10")] last:shadow-none flex items-center justify-between;
                            .main_name{@apply flex items-center gap-3 ;
                                .logo{@apply w-7 h-7 flex-none object-contain;}
                                .main_icon{@apply flex flex-none p-2;}
                                .name{@apply line-clamp-1;}
                            }
                            .main_delete{@apply hidden cursor-pointer;}

                            &:hover{
                                .main_delete{@apply flex;}
                            }
                        }
                    }
                }
            }
        }


        .main_empty_container{@apply flex justify-center items-center h-full p-6;}
    }


    .link_disabled{@apply grayscale opacity-70;}
}
.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.agenda_download_popup{ max-width: 600px;
                .main_header_modal{@apply shadow-[0_1px_0_theme("colors.dark.10")] p-7;}


                .main_body_modal{@apply px-0 py-2;
                
                    .main_list_annotations{@apply h-[380px] pr-7;
                        .main_list_items{@apply pl-7;
                            .item_list{@apply flex items-center gap-3  py-3;
                                .main_icon{@apply flex;}
                                .name{@apply line-clamp-1;}
    
                                &.goal{
                                    &.active{@apply bg-interactive-10;}
                                }

                                &.sticky_item{@apply sticky top-0 shadow-[0_1px_0_theme("colors.dark.10")] last:shadow-none z-[3] bg-white;}
                            }
                        }
                    }
                }

                .main_footer_modal{@apply shadow-[0_-1px_0_theme("colors.dark.10")] p-7;}
            }
        }
    }
}
.main_subheader{height: 64px;
    .wrap_subheader{@apply h-[64px] min-w-[980px] bg-white shadow-[0_1px_0_theme("colors.dark.10")] fixed w-full px-9 flex justify-between items-center gap-8 z-30;
        .left_content{@apply flex items-center gap-5;
        
            .main_back{@apply flex p-2 shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[2px] cursor-pointer hover:bg-dark-10/50;}

            .main_logo{
                .logo{@apply h-[25px] block w-auto;}
            }

            .main_title{@apply flex items-center gap-6;}
            .title{@apply text-22 leading-[1.2em] font-bold;
                &.small{@apply text-16;}
            }
            .subtitle{@apply text-18 font-medium text-dark-70;}

            .main_intro{@apply grid;
                .main_desc{@apply overflow-hidden flex max-w-full pt-1;
                    .desc{@apply text-14 text-dark-70 truncate;}
                }
            }


            .main_tag{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[4px] flex items-center py-2 pl-3 pr-4 text-15 gap-2;
                .logo{@apply w-7 h-7 flex-none object-contain;}
                .main_icon{@apply flex p-2;}
            }

            .note{@apply text-12 text-dark-70 ;}
        }

        .right_content{@apply flex-none;}
    }
}
.main_comp_box_content_plan{@apply flex gap-5;
    .main_comp_box_content_plan__detail{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[10px] p-6 bg-white flex-1;


        .main_counter{@apply flex gap-2 items-center;
            .counter{@apply text-20 font-bold;}
        }

        .main_title{@apply text-12 font-bold uppercase text-dark-70 pt-2;}

        .main_list_desc{@apply grid gap-2 pt-4;
            .item_desc{@apply flex gap-2 items-center text-dark-70 text-13;
                .counter{@apply text-14 text-dark-100 font-medium;}
            }
        }
    }
}
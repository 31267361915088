.main_comp_paginator{ @apply flex py-3 pt-5 px-5 justify-end;
    .wrap_comp_paginator{@apply flex gap-5 items-center;
    
        .main_counter{@apply shadow-[1px_0_0_theme("colors.dark.10")] pr-5;
            .counter{@apply text-12 font-medium text-dark-50;}
        }


        .main_paginator{@apply flex gap-1 items-center;
            .item_paginator{@apply cursor-pointer text-12 font-medium text-dark-70 relative flex items-center justify-center rounded-[2px] w-8 h-8 select-none hover:bg-dark-10;
                &.active{@apply  shadow-[inset_0_0_0_1px_theme("colors.dark.30")] bg-white;}
                &.fill{@apply pointer-events-none w-4 h-7;}
                &.dis{@apply pointer-events-none opacity-10;}
            }
        }
    }
}
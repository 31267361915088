.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.plan_original_text_popup{max-width: 900px;


                .main_header_modal{@apply shadow-[0_1px_0_theme("colors.dark.10")] py-8 px-11 relative;}


                .main_body_modal{@apply py-8 px-11 max-h-[calc(100vh-300px)] overflow-auto;
                    .main_editor_content{
                        .wrap_editor_content{
                            .main_editor{@apply min-h-[50vh] p-0;
                                .ce-block__content{@apply max-w-full;}
                            }
                        }
                    }

                    .change_step_in{ animation: changeIn 250ms forwards ease-in; }

                    .change_step_out{animation: changeIn 250ms forwards ease-in reverse;}

                    @keyframes changeIn {
                        0%{opacity: 0; max-height: 0px; overflow: hidden;}
                        24%{opacity: 0; max-height: 0px; overflow: hidden;}
                        25%{overflow: hidden;}
                        60%{max-height: 330px;}
                        61%{max-height: unset;}
                        100%{opacity: 1;}
                    }
                }

                .main_footer_modal{@apply shadow-[0_-1px_0_theme("colors.dark.10")] py-8 px-11;}
            }
        }
    }
}

.main_box{
    .main_date{@apply bg-primary-10 rounded-[2px] px-3 py-[1px] text-primary-100 inline-block font-medium uppercase text-13 mb-3;}
    .main_item{@apply relative pl-5 pb-6 last:pb-0;
        &::after{@apply content-[""] w-3 h-3 rounded-full bg-dark-10 shadow-[0_0_0_6px_theme("colors.white")] absolute top-[.5em] left-0 ;}
        &::before{@apply content-[""] w-[1px] h-full rounded-full bg-dark-10 top-[.5em] left-[4px] -translate-x-1/2 absolute  ;}

        &:last-child::before{@apply content-none;}
        
        &.last::before{@apply content-none;}

        .main_title{@apply text-14;
            .user{@apply text-dark-70 font-bold;}
        }

        .main_desc{@apply flex gap-3 pt-3;
            .main_icon{@apply pt-1;}
            .desc{@apply line-clamp-2 text-dark-70;}
        }
    }
}
.main_sidebar{
    .wrap_sidebar{@apply fixed h-full w-[450px] max-w-[450px] pt-[64px] shadow-[1px_0_0_theme("colors.dark.10")] top-0 flex z-[13] flex-col;
    
    
        .main_header_sidebar{@apply shadow-[0_2px_8px_theme("colors.dark.100")1a] pl-9 pr-7 pt-5 pb-6 items-center relative z-10 bg-white;

            .main_intro{@apply flex gap-4 justify-between pb-4 items-center;
                .title{@apply uppercase font-bold text-dark-50 text-13;}
            }

            .main_filters{@apply flex gap-4;
                .main_search{@apply w-full;}
            }
        }


        .main_content_sidebar{@apply flex-1 flex flex-col max-h-full overflow-auto;
            .main_list_sidebar{@apply overflow-auto max-h-full h-full flex;
                .wrap_list_sidebar{@apply pt-6 pb-11 pl-9 pr-7 w-full relative grid gap-5;}
            }
        }
    }
}
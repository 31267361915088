.main_item{@apply flex justify-between items-center gap-4 shadow-[0_1px_0_theme("colors.dark.10")] last:shadow-none p-7;

    .left_content{
        .main_title{@apply flex items-center gap-2;
            .main_icon{@apply flex;}
            .title{@apply text-16 font-bold;}
        }
        .description{@apply text-14 max-w-[575px] text-dark-70 pt-2;}
    }

    .right_content{@apply flex-none;}
}
.main_icon{@apply flex p-[1px] rounded-full;

    &.icon_status{

        .icon{@apply bg-white;}

        &.check{@apply bg-success-70;}
        &.cross{@apply bg-red-100;}
        &.stopwatch{@apply bg-blue-100;}
        &.lightning{@apply bg-yellow-100;}
        &.ban{@apply bg-fuxia-100;}
        &.lock{@apply bg-dark-20;
            .icon{@apply bg-dark-100;}
        }
    }
}

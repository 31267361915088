.main_box{@apply flex justify-between items-center;

    .left_content{@apply flex gap-5 items-center;
        .main_icon{@apply flex;}
        .name{@apply text-16 font-bold line-clamp-1;}
        .desc{@apply text-14 text-dark-70 leading-5 line-clamp-1;}
    }

    .right_content{@apply flex gap-7; 
        .main_counter_container{@apply flex gap-5;
            .main_list_counter{@apply flex gap-5 shadow-[-1px_0_0_theme("colors.dark.10")] pl-5 first:shadow-none first:pl-0;
                .main_counter{@apply flex items-center gap-2; }
            }
        }
    }

    &.boxed{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[4px] py-5 px-7;}

    &.small{
        .left_content{
            .name{@apply text-16;}
        }
    }
}

.bottom_content{@apply pt-5;

}
.main_subnav{height: 64px;
    .wrap_subnav{@apply h-[64px] bg-white shadow-[0_2px_8px_theme("colors.dark.100")0d] fixed w-[calc(100%_-_450px)] pl-6 pr-9 flex justify-between items-center z-[12] gap-5;
        .left_content{@apply flex items-center gap-5 max-w-full overflow-hidden;
        
            .main_intro{@apply flex gap-4 items-center max-w-full overflow-hidden;
                .tag_category{@apply flex-none text-13 text-primary-100 font-medium py-1 px-3 uppercase rounded-[2px] bg-primary-10;}
                .title{@apply text-14 truncate;}
                .heading{@apply text-16 truncate font-medium;}
            }
        }
    }
}
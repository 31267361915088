.main_error_page{@apply flex flex-col min-h-screen;
    .main_error{@apply flex-1 flex flex-col items-center justify-center px-7 py-13;

        .main_image{
            .image{@apply h-[140px] block;}
        }

        .main_desc{@apply pt-9;
            .title{@apply text-22 font-bold;}
            .desc{@apply text-dark-70 pt-3;}
        }

        .main_cta{@apply pt-10;}
    }
}
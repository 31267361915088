.main_action_compile{@apply bg-white rounded-[6px] p-7;
    .main_header{@apply flex pb-6 justify-between;
        .main_title{@apply flex items-center gap-2;
            .main_icon{@apply flex;}
            .title{@apply text-18 font-bold leading-[1.2em] uppercase;}
        }
    }

    .main_detail{@apply p-5 rounded-[4px] bg-dark-5 grid gap-6;
        .main_item{
            .main_title{@apply flex items-center gap-2;
                .main_icon{@apply flex;}
                .title{@apply text-12 text-dark-50;}
            }
            .value{@apply pt-2 text-12;
                a{@apply underline font-medium;}
            }
        }

        .group_items{@apply flex gap-9;}
    }

    .main_form{@apply pt-5;}
}
.main_sidebar{
    .wrap_sidebar{@apply fixed h-full w-[450px] max-w-[450px] pt-[64px] shadow-[1px_0_0_theme("colors.dark.10")] bg-light-gray top-0 flex z-[13] flex-col;
    
    
        .main_header_sidebar{@apply pl-9 pr-7 py-7 gap-4 items-center relative z-10;
            .main_title{@apply pb-5; 
                .title{@apply text-16 font-medium text-dark-70;}
            }
        }


        .main_content_sidebar{@apply flex-1 flex flex-col max-h-full overflow-auto pl-9;
            
            .main_heading{@apply shadow-[0_1px_0_theme("colors.dark.10")] pr-7 flex justify-between items-center pb-5;
                .main_title{
                    .title{@apply text-14 text-dark-50 uppercase font-bold;}
                }
            }

            .main_list_item{@apply pr-7 py-6;
                .wrap_list_item{@apply grid gap-3;}
            }
        }
    }
}
.main_range_input{position: relative; height: 20px; display: flex; align-items: center;

  input[type="range"] { -webkit-appearance: none; appearance: none; background: transparent; cursor: pointer; width: 100%; position: relative; z-index: 1; margin: unset; height: 10px;
  
      /* Removes default focus */
      &:focus {outline: none;}

      /***** Chrome, Safari, Opera and Edge Chromium styles *****/
      /* slider track */
      &::-webkit-slider-runnable-track {@apply bg-dark-100/10; border-radius: 20px; height: 8px; }
      &::-moz-range-track { @apply bg-dark-100/10; border-radius: 20px; height: 8px; }
      
      /* slider thumb */
      &::-webkit-slider-thumb { -webkit-appearance: none; /* Override default look */ appearance: none; margin-top: -10px; /* Centers thumb on the track */ 
         /*custom styles*/
        @apply bg-interactive-100 border-4 shadow-[0_0_0_2px_theme("colors.white"),0_5px_10px_theme("colors.dark.20")] border-white; height: 28px; width: 28px; border-radius: 50%; z-index: 2;
      }
      /* slider thumb */
      &::-moz-range-thumb { -webkit-appearance: none; /* Override default look */ appearance: none; margin-top: -10px; /* Centers thumb on the track */ border: unset;
         /*custom styles*/
         @apply bg-interactive-100 border-4 shadow-[0_5px_10px_theme("colors.dark.20")] border-white; height: 28px; width: 28px; border-radius: 50%; z-index: 2;
      }

      /* Focus */
      &:focus::-webkit-slider-thumb {@apply outline-2 outline-primary-30; outline-offset: 0px;}
      &:focus::-moz-range-thumb {@apply outline-2 outline-primary-30; outline-offset: 0px;}
  }

  // Range Value
  .range_value{position: absolute; height: 8px; @apply bg-interactive-40; top: 50%; transform: translateY(-50%); border-radius: 20px; pointer-events: none; max-width: 100%;}


  input[type="range"] {
      /* slider thumb */
      &::-webkit-slider-thumb {height: 18px; width: 18px; margin-top: -5px;}
      /* slider thumb */
      &::-moz-range-thumb { height: 18px; width: 18px; margin-top: -4px;}
  }
}
// ––––––––––––––
// Radio
@layer components {
	.radio_landing{@apply inline-block relative select-none;

		label{@apply inline-block relative text-14 pl-[30px] font-normal align-top leading-[22px] cursor-pointer;

			&:after{@apply opacity-0 transition-opacity;}

			&:before{@apply content-[""] absolute w-6 h-6 top-[1px] left-[1px] rounded-full shadow-[inset_0_0_0_1px_theme("colors.dark.30")] bg-white;}

			&:active{
				&:before{@apply shadow-[inset_0_0_0_1px_theme("colors.dark.50"),0_1px_1px_theme("colors.dark.10")] absolute;}
			}

			&:hover{
				&:before{@apply shadow-[inset_0_0_0_1px_theme("colors.dark.50"),0_2px_2px_theme("colors.dark.10")] absolute;}
			}
		}

		&.dis{
			label{@apply pointer-events-none text-dark-30;

				&:before{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] bg-dark-10 text-dark-20 absolute;}
			}
		}

		&.err{
			label{
				&:before{@apply shadow-[0_0_0_1px_theme("colors.red.100")] absolute;}
			}
		}

		&.small{
			label{padding-left: 22px;
				&:before{@apply content-[""] w-[14px] h-[14px] top-[4px] left-[1px] rounded-full shadow-[inset_0_0_0_1px_theme("colors.dark.30")] bg-white absolute;}
			}

			input[type=radio]:checked + label{
				&:after{@apply top-[7px] left-[4px] h-3 rounded-full bg-interactive-100 z-[2] opacity-100 animate-[onRadioCheck_ease-in-out_.4s_forwards] w-3;}
			}
		}


		// Checked
		input[type=radio]:checked + label{
			&:after{@apply content-[""] top-[4px] left-[4px] -translate-x-1/2 -translate-y-1/2 w-[14px] h-[14px] rounded-full bg-interactive-100 z-[2] opacity-100 animate-[onRadioCheck_ease-in-out_.4s_forwards] absolute;}
			&:before{@apply shadow-[inset_0_0_0_1px_theme("colors.interactive.100"),0_0px_1px_theme("colors.dark.100")1a] absolute;}
		}


		@keyframes onRadioCheck{
			0%{transform: scale(0);}
			50%{transform: scale(1.05);}
			100%{transform: scale(1);}
		}
	}
}
@layer components {

	// Div
	.main_select_landing{@apply relative w-full z-[2] align-top;

		&:hover{
			.wrap_arrow{@apply opacity-100;}
		}

		&.dis{
			.wrap_arrow{@apply grayscale;}
			select.landing{@apply bg-dark-70/10 shadow-none pointer-events-none text-dark-40 select-none cursor-default;}
		}

		// Span
		.wrap_arrow{ @apply absolute w-5 h-5 right-[10px] top-1/2 -translate-y-1/2 z-[4] pointer-events-none;}

		select.landing{ cursor: pointer; @apply pr-[38px] cursor-pointer rounded-[6px] text-dark-70 font-bold;
			&:hover{ /*background: rgba($dark, 0.08);*/ /* box-shadow: none !important; */
				&~.arrow{z-index: 4;}
			}
			
			&:focus{ /*background: rgba($corp, 0.12);*/ /* box-shadow: none !important; */
				&~.placeholder{ font-weight: 700 !important;}
				&~.arrow{z-index: 4;}
			}

			&:focus + .wrap_arrow{opacity: 1;
				/* &:before, &:after{background: $corp;} */
			}

			&.big{ padding-right: 38px;}

			&.small{padding: 6px 34px 6px 12px;}
		}
	}


	select:-moz-focusring {
		color: transparent !important;
		text-shadow: 0 0 0 #000 !important;
	}

	option:not(:checked) {
		color: black !important; /* prevent <option>s from becoming transparent as well */
	}

}
.main_sidebar{
    .wrap_sidebar{@apply fixed h-full w-[450px] max-w-[450px] bg-white pt-[64px] shadow-[1px_0_0_theme("colors.dark.10")] top-0 flex z-[13] flex-col;
    
    
        .main_header_sidebar{@apply shadow-[0_2px_8px_theme("colors.dark.100")1a] pl-9 pr-7 py-5 relative z-10 bg-white;
            .wrap_header_sidebar{@apply flex gap-4 items-center relative z-10;
                .main_search{@apply w-full;}
                .main_button{@apply flex rounded-[2px] p-2 cursor-pointer hover:bg-interactive-10 select-none relative;
                    &[data-active="true"]:not([data-opened="true"]){@apply bg-interactive-10;}
                    &[data-opened="true"]{@apply bg-interactive-100;}
                }
                .main_buttons{@apply flex items-center gap-2;}
            }

            .main_filters{@apply pt-3 flex gap-[10px] flex-wrap;}
        }


        .main_content_sidebar{@apply flex-1 flex flex-col max-h-full overflow-auto relative;
            .main_list_sidebar{@apply overflow-auto max-h-full h-full flex;
                .wrap_list_sidebar{@apply pt-6 pb-11 pl-9 pr-7 w-full relative;}
            }


            .main_loading{@apply absolute w-full h-full bg-white/70 flex justify-center items-center backdrop-blur-sm;}
        }
    }
}
.main_layer{@apply fixed top-0 left-0 w-full h-full  shadow-[inset_0_0_0_4px_theme("colors.interactive.100")] z-50 pointer-events-none;


    .main_session{@apply bg-interactive-100 absolute bottom-0 left-0 px-4 py-[6px] w-full text-white flex items-center gap-3 font-medium; pointer-events: all !important;
        .main_user{@apply rounded-full shadow-[inset_0_0_0_1px_theme("colors.dark.10")] w-7 h-7 flex justify-center items-center bg-white text-primary-100 font-bold text-10 flex-none;}
        .label{@apply truncate flex-1;}
        .main_cta{@apply flex-none flex gap-3;
            .toggle{@apply bg-white flex items-center gap-2 rounded-[4px] p-2;}
        }
    }
}
.main_editor_content{
    .wrap_editor_content{@apply max-w-[1200px] mx-auto w-full;
        .main_editor{@apply min-h-[90vh] rounded-[4px] shadow-[0_0_1px_theme("colors.dark.10")1a,0_5px_10px_theme("colors.dark.10")1a] bg-white p-8; font-family: 'Helvetica', 'Arial', sans-serif;
            .cdx-block{@apply py-1;}
            .ce-block__content{max-width: calc(100% - 58px); margin-right: 0;}
            .ce-toolbar__content{max-width: calc(100% - 98px);}
            textarea.ce-code__textarea{resize: none; min-height: unset !important;}
            h1,h2,h3,h4,h5,h6{@apply font-bold;}
            h1{@apply text-20;}
            h2{@apply text-16;}
            h3{@apply text-15;}
            h4{@apply text-14;}
            h5{@apply text-14;}
            h6{@apply text-13;}
            p{@apply text-14;}
            .tc-wrap{

                --color-border : #0D0E29;

                .tc-table{@apply border-l border-dark-100; }

                .tc-add-column{@apply border-r border-dark-100; }

                .tc-table--heading .tc-row:first-child{@apply border-b; background: #d5dce4;
                    &::after{@apply -bottom-[1px] border-b; }
                }
            }
            .cdx-marker{
                &.marker-interactive{@apply bg-interactive-20;}
                &.marker-green{@apply bg-green-20;}
            }
            .ce-delimiter{
                &::before{content: ""; height: 80px;}
                &::after{@apply content-[""] w-full h-[1px] bg-dark-20 absolute left-0 top-1/2 ;}
            }
            .image-tool__caption{display: none;}

            .ce-justify-text--justify{text-align: justify;}
        }
    }

    // Editor • read only
    &.editor_read_only{
        .main_editor{@apply min-h-0 shadow-none;
            .ce-block__content{max-width: 100%;}
            .ce-toolbar__content{max-width: 100%;}
        }
    }
}

input, textarea, select{
	&.landing{ @apply text-14 p-[5px_12px_5px] min-h-[32px] min-w-[60px] relative z-0 text-dark-100 bg-white w-full font-medium shadow-[inset_0_0_0_1px_theme("colors.dark.30")]
		
			hover:shadow-[inset_0_0_0_1px_theme("colors.dark.50")] hover:z-[3]
			
			focus:shadow-[inset_0_0_0_1px_theme("colors.primary.100"),0_0_0_3px_theme("colors.interactive.70")] focus:z-[3] border-none
		; 

		// Disabled
		&.dis{@apply shadow-none text-dark-40 bg-dark-70/10 pointer-events-none select-none cursor-default;}

		&::-webkit-input-placeholder{@apply text-dark-50 normal-case;}
		&::-moz-placeholder{@apply text-dark-50;}

		&:focus{
			&::-webkit-input-placeholder{@apply text-dark-20;}
			&::-moz-placeholder{@apply text-dark-20;}
		}

		// Size variant
		&.small{@apply min-h-[32px] p-[6px_10px] relative; }
		&.big{@apply min-h-[40px] p-[9px_16px] relative; }

		// Style variant
		&.rounded_left{@apply rounded-br-none rounded-tr-none;}
		&.rounded_right{@apply rounded-bl-none rounded-tl-none;}
		&.square{@apply rounded-none}

		// Error
		&.err{@apply shadow-[inset_0_0_0_1px_theme("colors.red.100")]
				focus:shadow-[inset_0_0_0_1px_theme("colors.red.100"),0_0_0_3px_theme("colors.red.10")] relative
			;
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition-delay: 999999999999s;
	-webkit-transition: color 999999999999s ease-out, background-color 999999999999s ease-out;
}
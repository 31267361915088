.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.status_plan_crud_popup{ max-width: 600px;
                .main_header_modal{@apply shadow-[0_1px_0_theme("colors.dark.10")] p-7 block;
                    .main_plan{@apply flex gap-4 items-start pt-4;
                        .main_icon{@apply flex p-3 rounded-[4px] bg-primary-10;}
                        .main_info{
                            .name{@apply text-16 text-dark-100;}
                            .description{@apply text-14 text-dark-70;}
                        }
                    }
                }


                .main_body_modal{@apply px-0 py-0;
                    
                    .main_list_status{@apply pt-5;

                        .main_title{@apply flex items-center justify-between px-7 pb-4;
                            .left_content{@apply text-16 font-medium;}
                            .right_content{@apply cursor-pointer hover:opacity-70 text-interactive-100 flex gap-2 items-center text-12 font-bold;}
                        }

                        .main_table_status{@apply max-h-[400px] min-h-[400px] px-7 pb-7;}
                    }

                    .global_form_landing{@apply p-7;

                        .main_icon_list{@apply flex flex-wrap gap-3;
                            .main_icon{@apply p-2 shadow-[inset_0_0_0_1px_theme("colors.dark.10")] rounded-full flex hover:shadow-[inset_0_0_0_1px_theme("colors.dark.50")] cursor-pointer;
                                &.active{@apply outline outline-2 outline-interactive-100 outline-offset-2;}
                            }
                        }
                    }
                }

                .main_footer_modal{@apply shadow-[0_-1px_0_theme("colors.dark.10")] p-7;
                    &.empty{@apply p-4;}
                }
            }
        }
    }
}
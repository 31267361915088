.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.move_action_popup{ max-width: 600px;
                .main_header_modal{@apply shadow-[0_1px_0_theme("colors.dark.10")] p-7;
                
                    .main_action_detail{@apply pt-5;
                        .main_intro{@apply flex items-center gap-2;
                            .main_icon{@apply flex;}
                            .intro{@apply text-12 font-bold text-dark-50 uppercase;}
                        }

                        .name{@apply text-14 pt-2;}
                    }
                }


                .main_body_modal{@apply px-0 py-2;
                
                    .main_list_capthers{height: 380px;
                        .item_list{@apply flex items-center gap-3 px-7 py-3;
                            .main_icon{@apply flex;}
                            .name{@apply line-clamp-1;}

                            &.goal{@apply pl-12;
                                &.active{@apply bg-interactive-10;}
                            }
                        }
                    }
                }

                .main_footer_modal{@apply shadow-[0_-1px_0_theme("colors.dark.10")] p-7;}
            }
        }
    }
}
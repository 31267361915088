.main_modal_container{
    .wrap_modal_container{
        .main_modal_content{

            &.duplicate_popup{
                .main_header_modal{}


                .main_body_modal{@apply pb-7;
                
                    .main_subtitle{@apply flex gap-2 items-center;
                        .main_icon{@apply flex;}
                        .subtitle{@apply uppercase text-12 font-bold text-dark-50;}
                    }

                    .main_description{@apply pt-[6px] text-14;}

                    .main_counter_container{@apply flex items-center pt-[6px] gap-3;
                        .main_counter{@apply flex gap-2 items-center font-medium text-13;
                            .main_icon{@apply flex;}
                        }
                    }

                    .main_entities{@apply pt-5;}
                }
            }
        }
    }
}
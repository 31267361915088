@layer components{

    .global_form_landing{

        // ––––––––––––––––—
        // Base
        // ––––––––––––––––—

        .base{@apply pb-5 last:pb-0 ;

            .label_input{@apply text-12 font-bold text-dark-50 mb-3 inline-flex;
                &.required{@apply relative items-center gap-[6px] pl-5;
                    &:before{@apply content-[""] absolute w-[6px] h-[6px] bg-red-100 rounded-full left-2;}
                }
            }

            .group_label{@apply flex justify-between items-center mb-3;
                .label_input{@apply mb-0;}
            }

            // To be able to use the child
            &.child{ @apply flex gap-y-5 gap-x-7 justify-between;}

            // ––––––––––––––––
            // Child
            // ––––––––––––––––

            .child{@apply w-full;
                &.small{max-width: 100px;}
            }
        }
    }
}
.main_box{
    .main_intro{@apply flex justify-between items-center;
    
        .title{@apply text-18 font-bold;}
        .main_progress_container{@apply flex gap-5 items-center;
            .main_progress{@apply w-[134px] h-[5px] bg-dark-10 rounded-full relative;
                .progress{@apply absolute left-0 h-full rounded-full top-0 bg-secondary-100;}
            }
            .counter{@apply text-16 font-semibold;}
        }


    }

    .main_chart{@apply grid grid-cols-1 pt-6 gap-8;
        .left_content{@apply min-h-[150px] relative;}
        .right_content{
            .main_legend{@apply h-full;
                .wrap_legend{@apply flex flex-wrap items-center h-full gap-6;
                    .item_legend{
                        .main_value{@apply flex items-center;
                            .value{@apply text-14 font-bold;}
                        }
                        .label{@apply text-10 font-bold uppercase pt-1 text-dark-70;}
                    }
                }
            }
        }
    }
}
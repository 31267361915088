.main_empty{@apply flex items-center justify-center flex-1;
    .wrap_empty{@apply flex flex-col items-center;
        .main_image{@apply pb-9;
            .image{@apply block drop-shadow-[0px_5px_10px_theme("colors.dark.100")1a] w-[360px] mx-auto;}
        }


        .main_desc{@apply grid gap-3 text-center;
            .title{@apply text-22 font-bold;}
            .desc{@apply text-dark-70;}
        }

        .main_action{@apply pt-5;}
    }
}
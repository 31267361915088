.main_current_plan{
    .main_heading{@apply flex justify-between items-center gap-6;
        .title{@apply text-18 font-bold;}

        .main_cta{@apply flex items-center gap-5;}
    }

    .list_status{@apply pt-6;
        .title{@apply uppercase font-bold text-12 text-dark-70;}
        .wrap_list{@apply flex flex-wrap gap-5 pt-4;}
    }

    .main_progress_info{@apply flex  gap-7 pt-7 items-center;

        .list_info{@apply shadow-[1px_0_0_theme("colors.dark.10")] flex justify-between gap-7 pr-7;

            .info_plan{@apply flex items-center text-14 font-bold gap-2;}
        }

        .main_progress{@apply flex-1 flex items-center gap-5;
            .label{@apply uppercase font-bold text-12 text-dark-50;}
            .progress_container{@apply w-full h-[5px] relative bg-dark-10 rounded-full;
                .progress{@apply h-full bg-secondary-100 rounded-full absolute;}
            }
            .value{@apply text-14 font-bold;}
        }
    }

}

.main_empty{@apply py-13 text-center flex flex-col items-center;

    .main_icon{@apply flex pb-4;}

    .title{@apply text-16 font-bold text-dark-100;}
    .description{@apply text-13 pt-2 text-dark-70;}


    &.small{@apply py-6;
        .title{@apply text-14 font-bold text-dark-100;}
        .description{@apply text-12 pt-1 text-dark-70;}
    }
}
.main_section_box{
    .main_intro{@apply pb-3 pl-3;
        .intro{@apply text-16 font-bold uppercase text-dark-60;}
    }

    .main_box{@apply shadow-[0_0_0_1px_theme("colors.dark.10")] rounded-[10px] bg-white p-7;}


    &.unboxed{
        .main_box{@apply shadow-none bg-[unset] p-0;}
    }
}